import React, { Component } from 'react'
import close from '../../../../include/images/icon-close.svg'
import bluestar from '../../../../include/images/blue-star-icon.svg'
import downloadIcon from '../../../../include/images/file-download.svg'
import star from '../../../../include/images/start-0.svg'
import file from '../../../../include/images/prescription-file-icon.svg'
import IncompletePrescription from './incompletePrescription'
import { loadForPatient, getPrescription } from '../../../../store/prescription'
import { connect } from 'react-redux'
import Moment from 'moment'
import moment from 'moment'
import _ from 'lodash'
import down from '../../../../include/images/down-icon-1.svg'
import AvtarImage from '../../../common/avtarImage'
import TreatmentPlan from './treatmentPlan'
import { loadAnswertreatment, getPlan } from '../../../../store/treatmentPlan'
import { Tooltip as ReactTooltip } from 'react-tooltip'

class ListPrescription extends Component {
  state = {
    isShow2: false,
    id: '',
    number: '',
    age: '',
    list_id: '',
    caseRecord: '',
    detail: false,
    appointment_time: '',
    planForm: false,
  }

  togglePlan = () => {
    this.setState({ planForm: !this.state.planForm })
  }

  toggleDetail = list_id => {
    this.setState({
      list_id: list_id,
      detail: !this.state.detail,
    })
  }

  toggle2 = (id, num, age, type, caseRecord, appointment_time) => {
    this.setState({
      isShow2: !this.state.isShow2,
      id: id,
      number: num,
      age: age,
      type: type,
      caseRecord: caseRecord,
      appointment_time,
    })
  }
  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.id && this.props.id !== prevProps.id) {
      this.props.loadForPatient(this.props.id)
      this.props.loadAnswertreatment(this.props.id)
    }
  }

  render() {
    const { forPatient } = this.props
    const monthName = item => item.is_completed
    const result = _.groupBy(forPatient, monthName)
    const groupArrays = Object.keys(result).map(status => {
      return {
        status,
        doc: result[status],
      }
    })

    const copyPrescriptionData =
      (groupArrays &&
        groupArrays.filter(ele => ele.status == 'true').map(ele => ele.doc)) ||
      []
    const prescriptionMapData =
      copyPrescriptionData && copyPrescriptionData.length > 0
        ? copyPrescriptionData[0].map(ele => ({
            ...ele,
            label:
              'Prescriptions ' +
              moment(ele.appointment_time).utc().format('DD-MM-YYYY'),
            value: ele._id,
          }))
        : []

    return (
      <>
        <ReactTooltip className='testPurple' html={true} />
        <div
          class='sidebar-box list-box'
          id='prescriptions_side_panel '
          style={{ right: this.props.isShow1 === true ? '0px' : '-100%' }}>
          <div class='sidebar-head d-flex align-items-center'>
            <div
              className='d-flex align-items-center justify-content-between'
              style={{ width: '93%' }}>
              <h5>Prescriptions</h5>
              <div
                class='ml-auto bio-button'
                style={{ cursor: 'pointer' }}
                onClick={this.togglePlan}>
                {this.props.planAnswer && this.props.planAnswer.is_submitted ? (
                  <h5 className='tag'>Edit Treatment Plan</h5>
                ) : (
                  <h5 className='tag'>Add Treatment Plan</h5>
                )}
              </div>
            </div>
            {/* <h5>Prescriptions</h5> */}
            <div class='sidebar-right ml-auto d-flex align-items-centers'>
              <div
                class='close-img'
                onClick={() => this.props.toggle1(this.props.id)}>
                <img src={close} alt='' />
              </div>
            </div>
          </div>
          <div class='sidebar-body height-auto p-0'>
            <div class='sidebar-inner-content'>
              <div class='sic-head d-flex flex-wrap justify-content-between'>
                <div class='sic-head-left flex-grow-1 d-flex '>
                  <div class='sic-head-img'>
                    {this.props.image ? (
                      <img
                        src={process.env.REACT_APP_S3URL + this.props.image}
                        alt=''
                      />
                    ) : (
                      <AvtarImage />
                    )}
                  </div>
                  <div class='sic-head-text'>
                    <h4>
                      {this.props.first_name + ' ' + this.props.last_name}{' '}
                      <i>
                        {' '}
                        {this.props.star === true ? (
                          <img src={bluestar} alt='star-icon' />
                        ) : (
                          <img src={star} alt='star-icon' />
                        )}
                      </i>
                    </h4>
                    <p>{this.props.yesmindy_identification_number}</p>
                  </div>
                </div>
                {this.props.lastAppointment_time &&
                  'Last Appointment: ' +
                    moment(this.props.lastAppointment_time)
                      .utc()
                      .format('DD-MM-YYYY')}
              </div>
              <div class='sic-body'>
                <>
                  {groupArrays.length === 0 && 'No previous appointment found.'}
                  {groupArrays.map((e2, i) => (
                    <>
                      {e2.status === 'false' && (
                        <>
                          {e2.doc.map((e1, i) => (
                            <>
                              <div class='tpc-month-box'>
                                <h6>
                                  {Moment(e1.date.replace('-', '/')).format(
                                    'DD-MMM-YYYY'
                                  )}
                                </h6>
                              </div>
                              <div class='simple-links-box'>
                                <a
                                  data-tip={
                                    moment().diff(
                                      moment(e1.appointment_time).subtract({
                                        hours: 5,
                                        minutes: 30,
                                      }),
                                      'minutes'
                                    ) < 0
                                      ? 'Will be active after appointment time begins'
                                      : 'aaaaa'
                                  }
                                  style={{
                                    cursor:
                                      moment().diff(
                                        moment(e1.appointment_time).subtract({
                                          hours: 5,
                                          minutes: 30,
                                        }),
                                        'minutes'
                                      ) < 0
                                        ? 'initial'
                                        : 'pointer',
                                    opacity:
                                      moment().diff(
                                        moment(e1.appointment_time).subtract({
                                          hours: 5,
                                          minutes: 30,
                                        }),
                                        'minutes'
                                      ) < 0
                                        ? '0.5'
                                        : 1,
                                  }}
                                  href='javascript:void(0)'
                                  onClick={() => {
                                    if (
                                      moment().diff(
                                        moment(e1.appointment_time).subtract({
                                          hours: 5,
                                          minutes: 30,
                                        }),
                                        'minutes'
                                      ) >= 0
                                    ) {
                                      let findData = groupArrays.find(
                                        ele => ele.status === 'true'
                                      )

                                      const length =
                                        findData && findData.doc
                                          ? findData.doc.length
                                          : 0

                                      this.toggle2(
                                        e1._id,
                                        e2.doc.length - i + length,
                                        e1.patient_id.date_of_birth,
                                        e1.doctor_id.field_type &&
                                          e1.doctor_id.field_type.name ===
                                            'Psychiatrist'
                                          ? 'Psychiatrist'
                                          : 'Psychologist',

                                        e1.caseRecord.finish[
                                          e1.doctor_id.field_type.name ===
                                          'Psychologist'
                                            ? 'diagnosis'
                                            : 'diagnosisPsychiatrist'
                                        ].diagnosis.map(ed => ed.value),
                                        e1.appointment_time
                                      )
                                    }
                                  }}
                                  class='slb-link-box'
                                  id='prescriptions10'
                                  data-toggle='prescriptions_side_panel_1'>
                                  <div class='slb-box d-flex flex-wrap'>
                                    <div class='slb-img'>
                                      <img src={file} alt='' />
                                    </div>
                                    <div class='slb-text'>
                                      <div class='d-flex align-items-center'>
                                        <h3>
                                          Prescriptions{' '}
                                          {moment(e1.appointment_time)
                                            .utc()
                                            .format('LT')}{' '}
                                        </h3>
                                        <div class='ml-auto'>
                                          <span class='new-tags ml-2'>
                                            {' '}
                                            Incomplete
                                          </span>
                                        </div>
                                      </div>
                                      <p>
                                        <strong> {e1.doctor_id.name}</strong>,{' '}
                                        {e1.doctor_id.field_type.name}
                                      </p>
                                    </div>
                                  </div>
                                </a>
                              </div>
                            </>
                          ))}
                        </>
                      )}
                    </>
                  ))}
                </>
                {groupArrays.map((e2, i) => (
                  <>
                    {e2.status === 'true' && (
                      <div class='accordion' id='accordion1'>
                        {e2.doc.map((e1, i) => (
                          <>
                            <div class='tpc-month-box'>
                              <h6>
                                {Moment(e1.date.replace('-', '/')).format(
                                  'DD-MMM-YYYY'
                                )}
                              </h6>
                            </div>
                            <div class='item'>
                              <div
                                className={
                                  this.state.detail === true &&
                                  this.state.list_id == e1._id
                                    ? 'ih-box'
                                    : 'ih-box collapsed'
                                }
                                data-toggle='collapse'
                                data-target='#accor6'
                                aria-expanded={
                                  (this.state.detail === true &&
                                    this.state.list_id == e1._id &&
                                    'true') ||
                                  (this.state.detail === false && 'false')
                                }>
                                <div class='slb-box d-flex flex-wrap'>
                                  <div class='slb-img'>
                                    <img src={file} alt='' />
                                  </div>
                                  <div class='slb-text'>
                                    <h3
                                      style={{ cursor: 'pointer' }}
                                      onClick={() => this.toggleDetail(e1._id)}>
                                      {' '}
                                      Prescriptions{' '}
                                      {moment(e1.appointment_time)
                                        .utc()
                                        .format('LT')}{' '}
                                    </h3>
                                    <p>
                                      <strong>{e1.doctor_id.name}</strong>,{' '}
                                      {e1.doctor_id.field_type.name}
                                    </p>
                                  </div>
                                </div>
                                <div class='slb-icons-outer d-flex align-items-center justify-content-end'>
                                  {e1.prescription.created_at &&
                                    moment().diff(
                                      moment(e1.prescription.created_at),
                                      'minutes'
                                    ) <= 60 && (
                                      <i
                                        class='fa fa-edit mr-2'
                                        aria-hidden='true'
                                        onClick={() => {
                                          let findData = groupArrays.find(
                                            ele => ele.status === 'true'
                                          )
                                          const length1 =
                                            findData && findData.doc
                                              ? findData.doc.length
                                              : 0
                                          this.toggle2(
                                            e1._id,
                                            e2.doc.length - i + length1,
                                            e1.patient_id.date_of_birth,
                                            e1.doctor_id.field_type &&
                                              e1.doctor_id.field_type.name ===
                                                'Psychiatrist'
                                              ? 'Psychiatrist'
                                              : 'Psychologist',

                                            e1.caseRecord.finish[
                                              e1.doctor_id.field_type.name ===
                                              'Psychologist'
                                                ? 'diagnosis'
                                                : 'diagnosisPsychiatrist'
                                            ].diagnosis.map(ed => ed.value),
                                            e1.appointment_time
                                          )
                                        }}></i>
                                    )}

                                  <a
                                    href={'/prescription/' + e1.appointment_id}
                                    target='_blank'
                                    class='icon'>
                                    <i class='fa fa-download mr-2'></i>
                                    {/* <img src={downloadIcon} alt='' /> */}
                                  </a>

                                  <span
                                    style={{ cursor: 'pointer' }}
                                    class='icon'
                                    onClick={() => this.toggleDetail(e1._id)}>
                                    <i>
                                      <img src={down} alt='' />
                                    </i>
                                  </span>
                                </div>
                              </div>
                              <div
                                id='accor6'
                                className={
                                  (this.state.detail === true &&
                                    this.state.list_id == e1._id &&
                                    'collapse show') ||
                                  (this.state.detail === false && 'collapsing')
                                }
                                data-parent='#accordion1'>
                                {this.state.list_id == e1._id && (
                                  <>
                                    <div class='t-p'>
                                      {e1.doctor_id &&
                                        e1.doctor_id.clinic_details && (
                                          <div class='sidebar-info-box d-flex flex-wrap'>
                                            <div class='sib-left'>
                                              <img
                                                src={
                                                  process.env.REACT_APP_S3URL +
                                                  e1.doctor_id.clinic_details
                                                    .photos[0]
                                                }
                                                width='50'
                                              />
                                            </div>
                                            <div class='sib-right'>
                                              <h5>
                                                <small>
                                                  {e1.doctor_id.clinic_details
                                                    .name
                                                    ? e1.doctor_id
                                                        .clinic_details.name +
                                                      ', '
                                                    : ''}
                                                </small>
                                              </h5>
                                              <p>
                                                {e1.doctor_id.clinic_details
                                                  .address
                                                  ? e1.doctor_id.clinic_details
                                                      .address + ', '
                                                  : ''}
                                              </p>
                                              <p>
                                                {e1.doctor_id.clinic_details
                                                  .city
                                                  ? e1.doctor_id.clinic_details
                                                      .city + ', '
                                                  : ''}
                                              </p>
                                              <p>
                                                {e1.doctor_id.clinic_details
                                                  .phone
                                                  ? e1.doctor_id.clinic_details
                                                      .phone
                                                  : ''}
                                              </p>
                                            </div>
                                          </div>
                                        )}
                                      <div class='sidebar-info-box d-flex flex-wrap'>
                                        <div class='sib-left'>
                                          <h5>
                                            {e1.patient_id.first_name +
                                              ' ' +
                                              e1.patient_id.last_name}
                                          </h5>
                                          <p>
                                            Age:{' '}
                                            {moment().diff(
                                              e1.patient_id.date_of_birth,
                                              'years'
                                            )}{' '}
                                            yrs
                                          </p>
                                          {<p> Gender: {e1.patient_id.sex}</p>}
                                        </div>
                                        <div class='sib-right'>
                                          <h5>
                                            <small>
                                              UHID:{' '}
                                              {
                                                e1.patient_id
                                                  .yesmindy_identification_number
                                              }
                                            </small>
                                          </h5>
                                          <p>
                                            Date:{' '}
                                            {moment(e1.appointment_time)
                                              .utc()
                                              .format('lll')}
                                          </p>
                                        </div>
                                      </div>
                                      <div class='sidebar-content-box d-flex flex-wrap'>
                                        <div class='scb-left'>
                                          <p>Duration of session</p>
                                          <h6>
                                            {
                                              e1.prescription
                                                .duration_of_session
                                            }
                                          </h6>
                                        </div>
                                        <div class='scb-right'>
                                          <p>Session participants</p>
                                          <h6>
                                            <b>
                                              {e1.prescription
                                                .session_participants &&
                                                e1.prescription.session_participants.join(
                                                  ', '
                                                )}
                                            </b>
                                          </h6>
                                        </div>
                                      </div>
                                      <div class='sidebar-content-box d-flex flex-wrap'>
                                        <div class='scb-right'>
                                          <p>Diagnosis</p>
                                          <h6>
                                            {e1.prescription.diagnosis &&
                                              e1.prescription.diagnosis.replace(
                                                ',',
                                                ', '
                                              )}
                                          </h6>
                                        </div>
                                      </div>
                                      {e1.doctor_id.field_type.name ===
                                        'Psychologist' && (
                                        <div class='sidebar-content-box d-flex flex-wrap'>
                                          <div class='scb-left'>
                                            <p>Therapy Method</p>
                                            <h6>
                                              {e1.prescription.theray_method}
                                            </h6>
                                          </div>
                                          <div class='scb-right'>
                                            <p>Therapy Technique</p>
                                            <h6>
                                              {e1.prescription.therapy_technique
                                                .map(et => et.label)
                                                .join(', ')}
                                            </h6>
                                          </div>
                                        </div>
                                      )}
                                      <div class='sidebar-content-box d-flex flex-wrap'>
                                        <div class='scb-right'>
                                          <p>Chief Complaints</p>
                                          <h6>
                                            {e1.prescription.chief_complaints}
                                          </h6>
                                        </div>
                                        {e1.doctor_id.field_type.name ===
                                          'Psychologist' && (
                                          <div class='scb-right'>
                                            <p>Key issue/Themes</p>
                                            <h6>{e1.prescription.key_issue}</h6>
                                          </div>
                                        )}
                                      </div>
                                      {e1.doctor_id.field_type.name ===
                                      'Psychologist' ? (
                                        <></>
                                      ) : (
                                        // <div class="sidebar-content-box d-flex flex-wrap">
                                        //   <div class="scb-left full-width">
                                        //     <p>Notes</p>
                                        //     <h6>
                                        //       {e1.prescription
                                        //         .medical_prescription &&
                                        //         e1.prescription
                                        //           .medical_prescription[0]
                                        //           .notes}
                                        //     </h6>
                                        //   </div>
                                        // </div>
                                        <div class='sidebar-bg-box'>
                                          <div class='box-h-line'>
                                            <span>Rx</span>
                                          </div>
                                          <table class='table small-table table-responsive'>
                                            <thead>
                                              <tr>
                                                <th>Formulation</th>
                                                <th width='10%'>Composition</th>
                                                <th>Brand</th>
                                                <th>Dose</th>
                                                <th>Frequency</th>
                                                <th>Days</th>
                                                <th width='20%'>Note</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {e1.prescription.medical_prescription.map(
                                                (e3, i) => (
                                                  <tr>
                                                    <td>{e3.formulation}</td>
                                                    <td>{e3.composition}</td>
                                                    <td>{e3.brand}</td>
                                                    <td>{e3.dose}</td>
                                                    <td>
                                                      {e3.frequency &&
                                                        e3.frequency.map(em => (
                                                          <>
                                                            {em.label
                                                              ? em.label
                                                              : em.value}
                                                            <br />
                                                          </>
                                                        ))}
                                                    </td>
                                                    <td>{e3.days}</td>
                                                    <td>{e3.notes}</td>
                                                  </tr>
                                                )
                                              )}
                                            </tbody>
                                          </table>
                                        </div>
                                      )}

                                      <div class='accor-seprator'></div>
                                      {e1.doctor_id.field_type.name ===
                                      'Psychologist' ? (
                                        <>
                                          {/* <div class="sidebar-content-box d-flex flex-wrap">
                                            <div class="scb-left">
                                              <p>
                                                Technique I would use (Optional)
                                              </p>
                                              <h6>
                                                {e1.prescription.technique.join(
                                                  ",  "
                                                )}
                                              </h6>
                                            </div>
                                            <div class="scb-right">
                                              <p>
                                                Medicinal Plan I would use
                                                (Optional)
                                              </p>
                                              <h6>
                                                {e1.prescription.medical_plan.join(
                                                  ",  "
                                                )}
                                              </h6>
                                            </div>
                                          </div>
                                          <div class="sidebar-content-box d-flex flex-wrap">
                                            <div class="scb-left">
                                              <p>
                                                Conduct Following Assessments
                                                (Optional)
                                              </p>
                                              <h6>
                                                {e1.prescription.conduct.join(
                                                  ",  "
                                                )}
                                              </h6>
                                            </div>
                                          </div> */}
                                        </>
                                      ) : (
                                        <>
                                          <div class='sidebar-content-box d-flex flex-wrap'>
                                            <div class='scb-left'>
                                              <p>Radiology</p>
                                              <h6>
                                                {e1.prescription.radiology
                                                  .map(rs => rs.label)
                                                  .join(',  ')}
                                              </h6>
                                            </div>
                                            <div class='scb-right'>
                                              <p>Lab Investigation</p>
                                              <h6>
                                                {e1.prescription.lab_investigations
                                                  .map(rs => rs.label)
                                                  .join(',  ')}
                                              </h6>
                                            </div>
                                          </div>
                                        </>
                                      )}
                                      <div class='accor-seprator'></div>
                                      <div class='sidebar-content-box d-flex flex-wrap'>
                                        <div class='scb-left'>
                                          <p>Plan for next session</p>
                                          <h6>
                                            {
                                              e1.prescription
                                                .plan_for_the_next_session
                                            }
                                          </h6>
                                        </div>
                                        <div class='scb-right'>
                                          <p>
                                            {e1.doctor_id.field_type.name ===
                                            'Psychologist'
                                              ? 'Task allotted'
                                              : 'Special instructions'}
                                          </p>
                                          <h6>
                                            {e1.prescription.special_advice}
                                          </h6>
                                        </div>
                                      </div>
                                      <div class='sidebar-content-box d-flex flex-wrap'>
                                        <div class='scb-left'>
                                          <p>Follow up session</p>
                                          <h6>
                                            {moment(
                                              e1.prescription.nextFollowUp
                                            )
                                              .utc()
                                              .format('DD-MM-YYYY')}
                                          </h6>
                                        </div>
                                        <div class='scb-right'>
                                          <p>Referral, if any</p>
                                          <h6>{e1.prescription.referral}</h6>
                                        </div>
                                      </div>
                                      <div class='sidebar-content-box d-flex flex-wrap'>
                                        {e1.prescription.referral ===
                                          'Clinical Psychologist' ||
                                        e1.prescription.referral ===
                                          'Psychiatrist' ? (
                                          <div class='scb-right'>
                                            <p>Referral Doctor</p>
                                            <a
                                              style={{
                                                fontSize: '16px',
                                                fontStyle: 'normal',
                                              }}
                                              href={`${
                                                e1.prescription
                                                  .referral_doctor_id
                                                  ? '/dr/' +
                                                    e1.prescription
                                                      .referral_doctor_id
                                                      .user_name
                                                  : '/our-experts'
                                              }`}>
                                              {e1.prescription
                                                .referral_doctor_id
                                                ? e1.prescription
                                                    .referral_doctor_id.name
                                                : 'Any Expert'}
                                            </a>
                                          </div>
                                        ) : null}
                                        <div class='scb-left'>
                                          <p>
                                            {e1.doctor_id.field_type.name ===
                                            'Psychologist'
                                              ? 'Others'
                                              : 'To chemist'}
                                          </p>
                                          <h6>{e1.prescription.to_chemist}</h6>
                                        </div>
                                      </div>
                                      {e1.doctor_id.field_type.name ===
                                        'Psychologist' && (
                                        <div class='sidebar-content-box d-flex flex-wrap'>
                                          <div class='scb-left'>
                                            <p>Objective of the session</p>
                                            {e1.prescription.objective.map(
                                              o => (
                                                <li>{o}</li>
                                              )
                                            )}
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </>
                        ))}
                      </div>
                    )}
                  </>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            this.props.isShow1 === true ? 'overlay4 show' : 'overlay4'
          }></div>
        <IncompletePrescription
          time={this.state.appointment_time}
          number={this.state.number}
          yesmindy_identification_number={
            this.props.yesmindy_identification_number
          }
          age={this.state.age}
          sex={this.props.sex}
          toggle1={this.props.toggle1}
          first_name={this.props.first_name}
          last_name={this.props.last_name}
          image={this.props.image}
          star={this.props.star}
          isShow2={this.state.isShow2}
          toggle2={this.toggle2}
          id={this.state.id}
          patient_id={this.props.id}
          type={this.state.type}
          caseRecord={
            this.state.caseRecord ? this.state.caseRecord.join(', ') : ''
          }
          appointment_date={this.props.appointment_date}
          prescriptionData={prescriptionMapData}
        />
        <TreatmentPlan
          planForm={this.state.planForm}
          togglePlan={this.togglePlan}
          patientId={this.props.id}
        />
      </>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  loadForPatient: id => dispatch(loadForPatient(id)),
  loadAnswertreatment: id => dispatch(loadAnswertreatment(id)),
})
const mapStateToProps = state => ({
  forPatient: getPrescription(state).forPatient,
  planAnswer: getPlan(state).planAnswer,
})
export default connect(mapStateToProps, mapDispatchToProps)(ListPrescription)
