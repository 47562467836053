import React, { Component } from 'react'
import Header from './header'
import LeftBar from './leftBar'
import moment from 'moment'
import {
  calcelByDoctor,
  cancellAppointment,
  getAppointment,
  loadAllAppointmentForDoctor,
  loadAppointmentDetail,
} from '../../../store/appointment'
import { loadSeo } from '../../../store/seo'
import {
  getDoctor,
  loadDoctorProfile,
  loadoffSchedule,
  offSchedule,
  offScheduleDeactivate,
} from '../../../store/doctor'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import { Modal } from 'react-bootstrap'
import verticalDots from '../../../include/images/vertical-dots.svg'
import loaderIcon from '../../../include/images/loading-icon.svg'
import _ from 'lodash'
import AlertSuccess from '../../common/alertSuccess'
import AlertError from '../../common/alertError'
import { toast } from 'react-toastify'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction' // needed for dayClick
//import resourceTimelinePlugin from '@fullcalendar/resource-timeline'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import { Link } from 'react-router-dom'
import AvtarImage from '../../common/avtarImage'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import SelectOptions from '../common/selectOptions'

const days = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat']
class Calender extends Component {
  state = {
    popUp: false,
    loading: false,
    dropDown: false,
    selectedEvent: false,
    appointModel: false,
    timeModel: false,
    selectedSlot: false,
    formData: {
      checkBox: true,
      type: 'both',
    },
    finalData: [],
    offSchedules: [],
    sidebarToogle: false,
    startTime: '',
    endTime: '',
    fullDay: false,
    startStr: '',
    endStr: '',
  }

  timeDropdownNew = [
    { value: '0000', label: '12:00 AM' },
    { value: '0030', label: '12:30 AM' },
    { value: '0100', label: '01:00 AM' },
    { value: '0130', label: '01:30 AM' },
    { value: '0200', label: '02:00 AM' },
    { value: '0230', label: '02:30 AM' },
    { value: '0300', label: '03:00 AM' },
    { value: '0330', label: '03:30 AM' },
    { value: '0400', label: '04:00 AM' },
    { value: '0430', label: '04:30 AM' },
    { value: '0500', label: '05:00 AM' },
    { value: '0530', label: '05:30 AM' },
    { value: '0600', label: '06:00 AM' },
    { value: '0630', label: '06:30 AM' },
    { value: '0700', label: '07:00 AM' },
    { value: '0730', label: '07:30 AM' },
    { value: '0800', label: '08:00 AM' },
    { value: '0830', label: '08:30 AM' },
    { value: '0900', label: '09:00 AM' },
    { value: '0930', label: '09:30 AM' },
    { value: '1000', label: '10:00 AM' },
    { value: '1030', label: '10:30 AM' },
    { value: '1100', label: '11:00 AM' },
    { value: '1130', label: '11:30 AM' },
    { value: '1200', label: '12:00 PM' },
    { value: '1230', label: '12:30 PM' },
    { value: '1300', label: '01:00 PM' },
    { value: '1330', label: '01:30 PM' },
    { value: '1400', label: '02:00 PM' },
    { value: '1430', label: '02:30 PM' },
    { value: '1500', label: '03:00 PM' },
    { value: '1530', label: '03:30 PM' },
    { value: '1600', label: '04:00 PM' },
    { value: '1630', label: '04:30 PM' },
    { value: '1700', label: '05:00 PM' },
    { value: '1730', label: '05:30 PM' },
    { value: '1800', label: '06:00 PM' },
    { value: '1830', label: '06:30 PM' },
    { value: '1900', label: '07:00 PM' },
    { value: '1930', label: '07:30 PM' },
    { value: '2000', label: '08:00 PM' },
    { value: '2030', label: '08:30 PM' },
    { value: '2100', label: '09:00 PM' },
    { value: '2130', label: '09:30 PM' },
    { value: '2200', label: '10:00 PM' },
    { value: '2230', label: '10:30 PM' },
    { value: '2300', label: '11:00 PM' },
    { value: '2330', label: '11:30 PM' },
    { value: '2400', label: '12:00 AM' },
  ]

  reSchule = e => {
    e.preventDefault()
    this.props.history.push({
      pathname: '/doctor/reschedule-appointment',
      state: {
        appointment_id: this.state.selectedEvent.data._id,
        returnUrl: '/doctor/dashboard/calender',
      },
    })
  }
  cancellAppointment = e => {
    e.preventDefault()
    this.props.cancellAppointment(
      this.state.selectedEvent.data._id,
      this.callBack
    )
    this.setState({ loader: true })
  }
  callBack = res => {
    this.toggle()
    this.setState({ loader: false })
    if (this.state.startStr && this.state.endStr) {
      this.props.loadAllAppointmentForDoctor({
        start_date: this.state.startStr,
        end_date: this.state.endStr,
        calendar: true,
      })
    } else {
      this.props.loadAllAppointmentForDoctor({})
    }
    if (res.status === 200) {
      toast(<AlertSuccess message={'Appointment cancelled successfully'} />)
    } else {
      toast(<AlertError message={res.data.error} />)
    }
  }
  toggle = e => {
    if (e) e.preventDefault()
    this.setState({ popUp: !this.state.popUp })
  }
  componentDidUpdate = (prevProps, prevState) => {
    if (
      !this.props.offSchedulesLoading &&
      this.props.offSchedulesLoading !== prevProps.offSchedulesLoading
    ) {
      const offSchedules = []
      this.props.offSchedules.forEach(element1 => {
        const prepairData = element1.end_time
          ? {
              start: new Date(moment(element1.start_time).utc()),
              end: new Date(moment(element1.end_time).utc()),
              display: 'background',
              backgroundColor: 'red',
              _id: element1._id,
              type: element1.type,
            }
          : {
              start: moment(element1.start_time).utc().format('YYYY-MM-DD'),
              display: 'background',
              backgroundColor: 'red',
              _id: element1._id,
              type: element1.type,
            }
        offSchedules.push(prepairData)
      })
      this.setState({ offSchedules })
    }

    if (
      !this.props.profileLoading &&
      this.props.profileLoading !== prevProps.profileLoading
    ) {
      const finalData = []
      const allDays = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat']
      Object.keys(this.props.doctorProfile.schedules).forEach(element => {
        if (
          Array.isArray(this.props.doctorProfile.schedules[element]) &&
          this.props.doctorProfile.schedules[element].length > 0
        ) {
          allDays.splice(allDays.indexOf(element), 1)
        }
      })

      allDays.forEach(elementDay => {
        const record = {
          daysOfWeek: [days.indexOf(elementDay)], //Sundays and saturdays
          display: 'background',
          color: 'red',
          allDay: true,
        }
        finalData.push(record)
      })

      this.setState({ finalData })
    }
  }

  dropDownToggle = e => {
    e.preventDefault()
    this.setState({ dropDown: !this.state.dropDown })
  }

  toggleAppointModel = e => {
    if (e) e.preventDefault()
    this.setState({ appointModel: !this.state.appointModel })
  }
  toggleTimeModel = e => {
    if (e) e.preventDefault()
    this.setState({ timeModel: !this.state.timeModel })
  }

  componentDidMount() {
    document.body.className = 'dashboard'
    this.props.loadAllAppointmentForDoctor({})
    this.props.loadoffSchedule()
  }

  handleLoadAppointments = val => {
    this.props.loadAllAppointmentForDoctor({
      ...val,
      calendar: true,
    })
  }

  calculateEnd = (time, minut) => {
    var dt = new Date(time)
    dt.setMinutes(dt.getMinutes() + minut)
    return moment(dt).utc()
  }

  handleRadio = type => {
    const { formData } = this.state
    formData.type = type
    this.setState({ formData })
  }

  handleCheck = () => {
    const { formData } = this.state
    formData.checkBox = !formData.checkBox
    this.setState({ formData })
  }

  submitForm = e => {
    this.setState({ loading: true })
    e.preventDefault()

    const end_time =
      this.state.selectedSlot.startStr.length !== 10
        ? moment(this.state.selectedSlot.end).utc()
        : false

    let Payload = {}

    if (this.state.fullDay || !this.state.startTime) {
      Payload = {
        start_time: moment(this.state.selectedSlot.start).utc().toString(),
        date: moment(this.state.selectedSlot.start).utc().format('YYYY-MM-DD'),
        type: this.state.formData.type,
        inActive: this.state.formData.checkBox,
      }
    } else {
      const obj = { ...this.state.selectedSlot }
      let startTime = moment(obj.start)
        .set('hour', Number(this.state.startTime.substring(0, 2)) + 5)
        .set('minute', Number(this.state.startTime.substring(2, 4)) + 30)
        .toString()
      let endTime = moment(obj.start)
        .set('hour', Number(this.state.endTime.substring(0, 2)) + 5)
        .set('minute', Number(this.state.endTime.substring(2, 4)) + 30)
        .toString()

      Payload = {
        start_time: startTime,
        end_time: endTime,
        date: moment(this.state.selectedSlot.start).utc().format('YYYY-MM-DD'),
        type: this.state.formData.type,
        inActive: this.state.formData.checkBox,
      }
    }
    if (this.state.selectedSlot.startStr.length !== 10) {
      Payload.end_time = new Date(end_time).toString()
    }

    this.props.offSchedule(Payload, this.callback)
  }

  unBlock = id => {
    this.setState({ loading: true })
    this.props.offScheduleDeactivate(id, this.callback)
  }

  callback = res => {
    this.setState({
      loading: false,
      timeModel: false,
    })
    if (res.status === 200) {
      this.props.loadoffSchedule()
      toast(<AlertSuccess message={'Information saved'} />)
    } else {
      toast(<AlertError message={res.data.error} />)
    }
  }

  toggleSidebar = e => {
    if (e) e.preventDefault()
    this.setState({ sidebarToogle: !this.state.sidebarToogle })
  }

  selectChangeOption = e => {
    const { value, name } = e
    this.setState({ [name]: value })
  }

  render() {
    const dataLocal = this.props.getAppointment.data.map(ea => ({
      display: 'block',
      data: ea,
      id: 0,
      title: ea.patient_id
        ? `${ea.patient_id.first_name} ${
            ea.patient_id.last_name
          } - ${_.upperCase(ea.appointment_type)}`
        : 'New Patient',
      start: new Date(moment(ea.appointment_time).utc()),
      end: new Date(
        this.calculateEnd(ea.appointment_time, ea.doctor_id.schedules.time)
      ),
      borderColor:
        ea.appointment_type === 'online'
          ? '#5FB5D7'
          : ea.appointment_type === 'walkin'
          ? '#582BB8'
          : '#61C395',
      backgroundColor:
        ea.appointment_type === 'online'
          ? '#EDFFF6'
          : ea.appointment_type === 'walkin'
          ? '#E6DFF5'
          : '#ebfdf5',
      textColor: '#3b2772',
      eventRender: e => console.log('eventRender', e),
    }))
    const dataLocal1 = [
      ...dataLocal,
      ...this.state.finalData,
      ...this.state.offSchedules,
    ]
    var today = new Date()
    today.setHours(today.getHours() + 5)
    today.setMinutes(today.getMinutes() + 30)

    let filterOption = []

    const currentSlotItem =
      this.state.selectedSlot &&
      this.props.doctorProfile.schedules[
        moment(this.state.selectedSlot.start).format('ddd').toLowerCase()
      ]

    const currentSlot =
      (currentSlotItem &&
        (this.state.formData.type && this.state.formData.type === 'online'
          ? currentSlotItem.filter(ele => ele.is_online)
          : currentSlotItem &&
            this.state.formData.type &&
            this.state.formData.type === 'physical'
          ? currentSlotItem.filter(ele => ele.is_clinic)
          : [...currentSlotItem])) ||
      []

    let mapData =
      (currentSlot &&
        currentSlot.map(ele => {
          const startIndex = this.timeDropdownNew.findIndex(
            item => item.value == ele.start_time
          )
          const endIndex = this.timeDropdownNew.findIndex(
            item => item.value == ele.end_time
          )
          const currentEle = this.timeDropdownNew.slice(
            startIndex,
            endIndex + 1
          )
          filterOption = [...filterOption, ...currentEle]
        })) ||
      []

    const uniqueOptions = filterOption.filter((obj, index) => {
      return index === filterOption.findIndex(o => obj.value === o.value)
    })

    const filter = this.state.offSchedules.filter(ele => {
      return (
        moment.utc(ele.start).format('YYYY-MM-DD') ===
          moment(this.state.selectedSlot.start).format('YYYY-MM-DD') &&
        ele.type == this.state.formData.type
      )
    })

    return (
      <>
        <ReactTooltip className='testPurple' html={true} />
        <div class={this.state.sidebarToogle ? 'dashboard on' : 'dashboard'}>
          <LeftBar />
          <Header
            sidebarToogle={this.state.sidebarToogle}
            toggleSidebar={this.toggleSidebar}
          />
          <div class='dashboard-main-container'>
            {/* <div class="dashboard-main-heading">
            <h2>
              Welcome, <strong>Dr. Shah</strong>
            </h2>
            <p>Have a nice day at work</p>
          </div> */}
            <div className='dashboard-wrapper'>
              <div class='dw-header d-flex align-items-center'>
                <h3>My Calendar</h3>
                <div class='dw-right ml-auto mt-3'>
                  <Link
                    data-tip='For walk in/ online patients when you want to record their visit (physical, online, telephonic). Only for existing patients in your record. Else add new patient first to record their visit.'
                    to='/doctor/recordVisit'
                    class='btn btn-outline no-shadow'>
                    Record Visit
                  </Link>
                  <Link
                    data-tip='When you want to create an appointment for your existing patient on their behalf. If new patient then add the patient first.'
                    to='/doctor/newAppointment'
                    class='btn btn-outline no-shadow'>
                    + New Appointment
                  </Link>
                  <Link
                    data-tip='When you want to add a new patient to your dashboard so that you can create an appointment for them or record their visits.'
                    to='/doctor/addPatient'
                    class='btn btn-default'>
                    + New Patient
                  </Link>
                </div>
              </div>
              <div className='dw-main-content'>
                <FullCalendar
                  slotDuration={'01:00:00'}
                  nowIndicator={true}
                  now={today} // just for demo
                  displayEventTime={false}
                  eventTimeFormat={{
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: true,
                  }}
                  stickyHeaderDates={true}
                  height={650}
                  textEscape={false}
                  select={e => {
                    this.toggleTimeModel()
                    this.setState({ selectedSlot: e })
                  }}
                  longPressDelay={1}
                  selectable
                  timeZone='Asia/Kolkata'
                  headerToolbar={{
                    left: 'prev,today,next',
                    center: 'title',
                    right: 'dayGridMonth,timeGridWeek,timeGridDay',
                  }}
                  schedulerLicenseKey='CC-Attribution-NonCommercial-NoDerivatives'
                  weekends={true}
                  plugins={[
                    dayGridPlugin,
                    timeGridPlugin,
                    listPlugin,
                    interactionPlugin,
                    //resourceTimelinePlugin,
                  ]}
                  initialView='dayGridMonth'
                  events={dataLocal1}
                  eventClick={e => {
                    this.setState({
                      selectedEvent: e.event._def.extendedProps,
                    })
                    this.toggleAppointModel()
                  }}
                  datesSet={dateInfo => {
                    const currStart = moment(dateInfo.startStr).format(
                        'YYYY-MM-DD'
                      ),
                      currEnd = moment(dateInfo.endStr).format('YYYY-MM-DD')
                    if (this.state.startStr != currStart) {
                      this.setState(
                        {
                          startStr: currStart,
                          endStr: currEnd,
                        },
                        () => {
                          this.handleLoadAppointments({
                            start_date: currStart,
                            end_date: currEnd,
                          })
                        }
                      )
                    }
                  }}
                />
                <div class='dm-footer d-flex align-items-center'>
                  <div class='calendar-cradits d-flex align-items-center add-scrollbar scrollbar-dynamic'>
                    <div class='d-flex align-items-center'>
                      <div class='cc-item'>
                        <h5>Legend:</h5>
                      </div>
                      <div class='cc-item'>
                        <div class='cc-check-icon'></div>
                        Online
                      </div>
                      <div class='cc-item cc-physical'>
                        <div class='cc-check-icon'></div>
                        Clinic
                      </div>
                      <div class='cc-item cc-walk-in'>
                        <div class='cc-check-icon'></div>
                        Walkin
                      </div>
                      <div class='cc-item cc-shedule'>
                        <div class='cc-check-icon'></div>
                        Schedule Off
                      </div>
                      {/* <div class="cc-item cc-online">
                        <div class="cc-check-icon"></div>
                        Only Online
                      </div>
                      <div class="cc-item">
                        <div class="cc-check-icon"></div>
                        Only Clinic
                      </div> */}
                    </div>
                    <div class='calendar-cradits-right d-flex align-items-center'>
                      <div class='add-current-time-indicator'>
                        <span class='dots'></span>
                      </div>
                      Current Time
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Modal
            show={this.state.appointModel}
            onHide={this.toggleAppointModel}
            centered
            dialogClassName='profile'>
            {' '}
            <div class='dropright-header'>
              <h3>Appointment Details</h3>
              <div class='dropright'>
                <a
                  href='#!'
                  onClick={e => this.dropDownToggle(e)}
                  class='dropdown-toggle'
                  data-toggle='dropdown'>
                  <img src={verticalDots} alt='' />
                </a>
                <div
                  class={
                    this.state.dropDown
                      ? 'dropdown-menu2 add-arrow-shape show'
                      : 'dropdown-menu2 add-arrow-shape '
                  }>
                  <a
                    class='dropdown-item'
                    href='#'
                    onClick={e => {
                      this.dropDownToggle(e)

                      this.props.history.push({
                        pathname: '/doctor/patient-detail',
                        state: this.state.selectedEvent.data.patient_id._id,
                      })
                    }}>
                    View Profile
                  </a>
                  <a
                    class='dropdown-item'
                    href='#'
                    onClick={e => {
                      this.dropDownToggle(e)

                      this.props.history.push({
                        pathname: '/doctor/dashboard/case-record',
                        state: this.state.selectedEvent.data.patient_id._id,
                      })
                    }}>
                    Case Records
                  </a>
                  <a
                    class='dropdown-item'
                    href='#'
                    onClick={e => {
                      this.dropDownToggle(e)
                      this.toggle(e)
                      this.toggleAppointModel(e)
                    }}>
                    Modify Appointment
                  </a>
                </div>
              </div>

              <div className='person-details-box d-flex align-items-center flex-grow-1'>
                <div className='person-img has-active'>
                  {this.state.selectedEvent &&
                  this.state.selectedEvent.data.patient_id.thumb ? (
                    <img
                      src={
                        process.env.REACT_APP_S3URL +
                        this.state.selectedEvent.data.patient_id.thumb
                      }
                      alt=''
                    />
                  ) : (
                    <AvtarImage />
                  )}
                </div>
                <div className='person-text-box'>
                  <h3>
                    {this.state.selectedEvent &&
                      this.state.selectedEvent.data.patient_id.first_name +
                        this.state.selectedEvent.data.patient_id.last_name}
                  </h3>
                  <p>
                    {this.state.selectedEvent &&
                      this.state.selectedEvent.data.patient_id
                        .yesmindy_identification_number}
                  </p>
                </div>
              </div>
            </div>
            <a href='#!' className='dropright-body'>
              <p>Upcoming appointment</p>
              <div className='dropright-status online d-flex align-items-center'>
                <p>
                  On{' '}
                  <strong>
                    {moment(
                      this.state.selectedEvent &&
                        this.state.selectedEvent.data.appointment_time
                    )
                      .utc()
                      .format('D/M')}
                  </strong>{' '}
                  at{' '}
                  <strong>
                    {moment(
                      this.state.selectedEvent &&
                        this.state.selectedEvent.data.appointment_time
                    )
                      .utc()
                      .format('hh:mm a')}
                  </strong>
                </p>
                <span className='status'>
                  {this.state.selectedEvent &&
                    _.startCase(this.state.selectedEvent.data.appointment_type)}
                </span>
              </div>
            </a>
            <div class='dropright-footer'>
              <a
                href='#!'
                class='btn btn-blue-outline'
                onClick={e => {
                  this.dropDownToggle(e)
                  this.props.history.push({
                    pathname: '/doctor/patient-detail',
                    state: this.state.selectedEvent.data.patient_id._id,
                  })
                }}>
                View Notes & Thoughts
              </a>
              <a className='m-4' onClick={this.toggleAppointModel} href='#!'>
                <b>Close</b>
              </a>
            </div>
            {/* <Modal.Header closeButton>
            <Modal.Title>Appointment Detail</Modal.Title>
          </Modal.Header>
          <Modal.Body>Appointment Detail</Modal.Body> */}
          </Modal>
          <Modal
            className='modal fade blockModal'
            show={this.state.timeModel}
            onHide={this.toggleTimeModel}
            centered
            dialogClassName='profile'
            contentClassName='p-0'>
            {this.state.selectedSlot.start && (
              <div class='tbm-box'>
                <div class='tbm-head'>
                  {/* <div class="tbm-head-content d-flex align-items-center">
                  <h5>Block Time</h5>
                  <div class="custom-switch-outer d-flex align-items-center ml-auto">
                    <div class="custom-switch">
                      <input
                        onChange={this.handleCheck}
                        checked={this.state.formData.checkBox}
                        type="checkbox"
                        class="custom-switch"
                        id="custom-switch-0"
                      />
                      <label for="custom-switch-0"></label>
                    </div>
                  </div>
                </div> */}
                  {this.state.selectedSlot.startStr.length !== 10 ? (
                    <p>
                      Select options to block time from{' '}
                      <strong>
                        {moment(this.state.selectedSlot.start)
                          .utc()
                          .format('lll')}{' '}
                      </strong>{' '}
                      to{' '}
                      <strong>
                        {moment(this.state.selectedSlot.end)
                          .utc()
                          .format('h:mm a')}
                      </strong>
                    </p>
                  ) : (
                    <p>
                      Select options to block time for{' '}
                      <strong>
                        {moment(this.state.selectedSlot.startStr).format(
                          'MMMM Do YYYY'
                        )}{' '}
                      </strong>{' '}
                    </p>
                  )}
                </div>

                <div class='tbm-body'>
                  <p>Block time for:</p>
                  <div
                    class='
                  tbm-custom-radio-box
                  d-flex flex-column-fluid flex-lg-row
                  align-items-center
                  custom-radio-outer
                '>
                    <div class='custom-control custom-radio'>
                      <input
                        checked={this.state.formData.type === 'online'}
                        onChange={e => this.handleRadio('online')}
                        type='radio'
                        class='custom-control-input'
                        id='defaultGroupExample1'
                        name='groupOfDefaultRadios'
                      />
                      <label
                        class='custom-control-label'
                        for='defaultGroupExample1'>
                        Online
                      </label>
                    </div>

                    <div class='custom-control custom-radio'>
                      <input
                        onChange={e => this.handleRadio('physical')}
                        type='radio'
                        class='custom-control-input'
                        id='defaultGroupExample2'
                        name='groupOfDefaultRadios'
                        checked={this.state.formData.type === 'physical'}
                      />
                      <label
                        class='custom-control-label'
                        for='defaultGroupExample2'>
                        Clinic
                      </label>
                    </div>

                    <div class='custom-control custom-radio'>
                      <input
                        checked={this.state.formData.type === 'both'}
                        onChange={e => this.handleRadio('both')}
                        type='radio'
                        class='custom-control-input'
                        id='defaultGroupExample3'
                        name='groupOfDefaultRadios'
                      />
                      <label
                        class='custom-control-label'
                        for='defaultGroupExample3'>
                        Both
                      </label>
                    </div>
                  </div>
                  <div class='form-group d-flex flex-column flex-lg-row align-items-lg-center mt-3'>
                    {this.state.fullDay ? null : (
                      <div className='d-flex'>
                        <SelectOptions
                          name='startTime'
                          attrind={0}
                          attrnum='0'
                          onChange={this.selectChangeOption}
                          value={uniqueOptions.find(
                            es => es.value === this.state.startTime
                          )}
                          nameCl='small ml-0'
                          options={uniqueOptions}
                        />

                        <label
                          className='form-check-label mx-2'
                          htmlFor='flexCheckMon'>
                          to
                        </label>

                        <SelectOptions
                          name='endTime'
                          attrind={1}
                          attrnum='1'
                          onChange={this.selectChangeOption}
                          value={uniqueOptions.find(
                            es => es.value === this.state.endTime
                          )}
                          nameCl='small'
                          options={uniqueOptions}
                        />
                      </div>
                    )}

                    <div className='schedule-day'>
                      <div className='form-check'>
                        <input
                          name='mon'
                          onChange={e =>
                            this.setState({ fullDay: e.target.checked })
                          }
                          className='form-check-input'
                          type='checkbox'
                          value=''
                          id='flexCheckMon'
                          checked={this.state.fullDay}
                        />
                        <label
                          className='form-check-label ml-0'
                          htmlFor='flexCheckMon'>
                          Full Day
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                {this.state.offSchedules &&
                  this.state.offSchedules
                    .filter(
                      ele =>
                        moment.utc(ele.start).format('YYYY-MM-DD') ===
                        moment(this.state.selectedSlot.start).format(
                          'YYYY-MM-DD'
                        )
                    )
                    .filter(ele =>
                      this.state.formData.type === 'both'
                        ? ele.type == 'online' || ele.type == 'physical'
                        : ele.type == this.state.formData.type
                    )
                    .map((ele, i) => (
                      <>
                        {i === 0 && <div>Blocked time slots</div>}

                        <div className='d-flex align-items-center pb-3'>
                          {ele.end ? (
                            <p className='mb-0'>
                              <strong>
                                {moment(ele.start).utc().format('h:mm a')}{' '}
                                {ele.start_time}
                              </strong>{' '}
                              to{' '}
                              <strong>
                                {moment(ele.end).utc().format('h:mm a')}
                                {ele.end_time}
                              </strong>
                            </p>
                          ) : (
                            <p className='mb-0'>Full Day</p>
                          )}

                          <a
                            onClick={() => {
                              this.unBlock(ele._id)
                            }}
                            href='#!'
                            class='btn btn-default no-shadow ml-3'>
                            Unblock
                          </a>
                        </div>
                      </>
                    ))}

                <div class='tbm-footer d-flex align-items-center'>
                  <a
                    disable={this.state.loading}
                    onClick={e => this.toggleTimeModel(e)}
                    href='#!'
                    className={
                      this.state.loading
                        ? 'btn btn-outline no-shadow disabled'
                        : 'btn btn-outline no-shadow'
                    }
                    data-dismiss='modal'>
                    Cancel
                  </a>
                  {this.state.loading ? (
                    <a class='btn btn-default no-shadow btn-loading'>
                      <img src={loaderIcon} alt='' />
                    </a>
                  ) : (
                    <a
                      onClick={this.submitForm}
                      href='#!'
                      class='btn btn-default no-shadow'>
                      Submit
                    </a>
                  )}
                </div>
              </div>
            )}
          </Modal>
          <Modal
            className='modal fade custom-modal'
            show={this.state.popUp}
            onHide={this.toggle}>
            <div class='modal-body'>
              <h6>Modify Appointment?</h6>
              <p>Do you wish to cancel or reschedule your appointment</p>
              <div class='custom-modal-btn'>
                <a
                  disabled={this.state.loader}
                  href='#!'
                  class={
                    this.state.loader
                      ? 'btn btn-primary btn-block disabled'
                      : 'btn btn-primary btn-block'
                  }
                  onClick={e => this.cancellAppointment(e)}>
                  Cancel Appointment
                </a>
                <a
                  onClick={e => this.reSchule(e)}
                  href='#!'
                  class='btn btn-outline no-shadow btn-block'>
                  Reschedule Appointment
                </a>
                <a href='#!' class='btn-text' onClick={this.toggle}>
                  Don’t Modify
                </a>
              </div>
            </div>
          </Modal>
        </div>
      </>
    )
  }
}
const mapDispatchToProps = dispatch => ({
  loadSeo: page_key => dispatch(loadSeo(page_key)),
  loadDoctorProfile: () => dispatch(loadDoctorProfile()),
  loadoffSchedule: () => dispatch(loadoffSchedule()),
  loadAllAppointmentForDoctor: params =>
    dispatch(loadAllAppointmentForDoctor(params)),
  loadAppointmentDetail: (id, callback) =>
    dispatch(loadAppointmentDetail(id, callback)),
  offSchedule: (payLoad, callback) => dispatch(offSchedule(payLoad, callback)),
  offScheduleDeactivate: (payLoad, callback) =>
    dispatch(offScheduleDeactivate(payLoad, callback)),
  cancellAppointment: (id, callBack) => dispatch(calcelByDoctor(id, callBack)),
})
const mapStateToProps = state => ({
  profileLoading: getDoctor(state).profileLoading,
  doctorProfile: getDoctor(state).profile,

  offSchedules: getDoctor(state).offSchedules,
  offSchedulesLoading: getDoctor(state).offSchedulesLoading,
  getAppointment: getAppointment(state).doctorAppointment,
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Calender)
)
