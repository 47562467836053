import React, { Component } from 'react'
import Joi from 'joi-browser'
import Form from '../../common/form/form'
import { connect } from 'react-redux'
import DashboardTopBar from './common/dashboardTopBar'
import moment from 'moment'
import AlertError from '../../common/alertError'
import { toast } from 'react-toastify'
import { getCroppedImg } from './../../common/convasUtils'

import axios from 'axios'

import { State, City, Country } from 'country-state-city'

import {
  dobInvalid,
  dobRequired,
  sexRequired,
  firstNameRequired,
  lastNameRequired,
  cityRequired,
  stateRequired,
  lnameMax,
  fnameMax,
  emailRequired,
  emailInvalid,
  mobileInvalid,
  mobileRequired,
  mobileCodeRequired,
} from '../../common/misc'
import seUploadFun from '../../services/s3Services'
import {
  updatePatientProfile,
  loadPatientProfile,
  getPatient,
  patientDetail,
  updatePatientProfileByDoctor,
} from '../../../store/patient'
import { withRouter } from 'react-router-dom'
import { getMisc, loadCity, loadState } from '../../../store/misc'
import avatar from './../../../include/images/Icons/User.svg'
import rocket from './../../../include/images/rocket.svg'
import SubmitBtn from '../../common/form/submitBtn'
import verify from './../../../include/images/varify-check-icon.svg'
import { Modal } from 'react-bootstrap'
import Cropper from 'react-easy-crop'
import btnLoader from './../../../include/images/loading-icon.svg'
import Select from 'react-select'

class PatientDetails extends Form {
  state = {
    croppedAreaPixels: '',
    image: '',
    crop: { x: 0, y: 0 },
    zoom: 1,
    model: false,
    loading: false,
    btnClass: 'btn btn-primary btn-lg',
    stateOnchange: false,
    states: [],
    displayImage: '',
    photoStatus: '',
    data: {
      first_name: '',
      last_name: '',
      photo: '',
      date_of_birth: '',
      state: '',
      city: '',
      email: '',
      phone: '',
      mobileCode: {
        label: '+91 - India',
        value: 'IN',
        currLabel: '+91',
      },
      country: 'India',
    },
    errors: {
      first_name: '',
      last_name: '',
      photo: '',
      date_of_birth: '',
      state: '',
      city: '',
      email: '',
      phone: '',
      sex: '',
      mobileCode: '',
    },
    disableMobile: false,
    disableEmail: false,
    disableMobileCode: false,
    stateData: [],
    cityData: [],
  }
  schema = {
    photo: Joi.string()
      .allow('')
      .optional()
      .label('Photo')
      .error(() => {
        return { message: 'Photo not uploaded' }
      }),
    first_name: Joi.string()
      .required()
      .max(50)
      .label('First Name')
      .error(errors => {
        errors.forEach(err => {
          switch (err.type) {
            case 'any.empty':
              err.message = firstNameRequired
              break

            case 'string.max':
              err.message = fnameMax
              break
          }
        })
        return errors
      }),
    phone: Joi.number()
      .required()
      .label('Mobile number')
      .min(1000000000)
      .max(999999999999999)
      .error(errors => {
        errors.forEach(err => {
          switch (err.type) {
            case 'number.base':
              err.message = mobileRequired
              break

            case 'number.max':
              err.message = mobileInvalid
              break

            case 'number.min':
              err.message = mobileInvalid
              break
          }
        })
        return errors
      }),
    email: Joi.string()
      .email()
      .max(50)
      .required()
      .label('Email')
      .error(errors => {
        errors.forEach(err => {
          switch (err.type) {
            case 'any.empty':
              err.message = emailRequired
              break

            case 'string.email':
              err.message = emailInvalid
              break
            case 'string.max':
              err.message = emailInvalid
              break
          }
        })
        return errors
      }),
    last_name: Joi.string()
      .required()
      .max(50)
      .label('Last Name')
      .error(errors => {
        errors.forEach(err => {
          switch (err.type) {
            case 'any.empty':
              err.message = lastNameRequired
              break

            case 'string.max':
              err.message = lnameMax
              break
          }
        })
        return errors
      }),
    date_of_birth: Joi.date()
      .max(new Date().setFullYear(new Date().getFullYear() - 18))
      .required()
      .label('Date of birth')
      .error(errors => {
        errors.forEach(err => {
          switch (err.type) {
            case 'date.base':
              err.message = dobRequired
              break
            case 'date.max':
              err.message = dobInvalid
              break
          }
        })
        return errors
      }),
    city: Joi.object()
      .required()
      .error(() => {
        return { message: cityRequired }
      }),
    mobileCode: Joi.object()
      .required()
      .error(() => {
        return { message: mobileCodeRequired }
      }),
    country: Joi.string(),
    state: Joi.object()
      .required()
      .error(() => {
        return { message: stateRequired }
      }),
    sex: Joi.string()
      .required()
      .label('Sex')
      .error(() => {
        return { message: sexRequired }
      }),
  }

  loadStateApi = val => {
    const stateDataValue = State.getStatesOfCountry(val)
    let formatData = stateDataValue.map(ele => ({
      label: ele.name,
      value: ele.isoCode,
    }))
    this.setState({ stateData: [...formatData] })
  }

  loadCityApi = (val, coutryCode) => {
    const cityDataValue = City.getCitiesOfState(coutryCode, val)
    let formatData = cityDataValue.map(ele => ({
      label: ele.name,
      value: ele.name,
    }))
    this.setState({ cityData: [...formatData] })
  }

  customStyles = {
    indicatorSeparator: styles => ({ display: 'none' }),
    option: (provided, state) => ({
      ...provided,
      '&:hover': {
        backgroundColor: 'rgb(95, 50, 187, 10%)',
        color: '#621ac0',
      },

      'color': state.isSelected ? '#621ac0' : 'black',
      'backgroundColor': state.isSelected
        ? 'rgb(95, 50, 187, 10%)'
        : provided.backgroundColor,
    }),
    control: (base, { isDisabled }) => ({
      ...base,
      minHeight: 60,
      borderColor: '#e0e0e0',
      borderRadius: 8,
      boxShadow: '0 !important',
    }),
  }
  componentDidMount = () => {
    if (this.props.isDoctor) {
      this.props.loadPatientProfileDoctor(this.props.patientId)
    } else {
      this.props.loadPatientProfile()
    }
    document.body.classList.add('dashboard')
    document.body.classList.remove('p-0')
  }
  componentWillUnmount = () => {
    document.body.classList.remove('dashboard')
    document.body.classList.add('p-0')
  }
  componentDidUpdate = (prevProps, prevState) => {
    if (
      typeof this.state.data.city === 'string' &&
      prevProps.getMisc &&
      prevProps.getMisc.city &&
      prevProps.getMisc.city.length > 0
    ) {
      const findData = prevProps.getMisc.city.find(
        ele => ele._id === this.state.data.city
      )
      if (findData)
        this.setState({
          data: {
            ...this.state.data,
            city: { label: findData.city, value: findData._id },
          },
        })
    }
    window.$('#state').niceSelect('update')
    if (
      this.props.patientProfile._id &&
      !this.props.loading &&
      this.props.loading !== prevProps.loading
    ) {
      const {
        first_name,
        last_name,
        email,
        mobile: phone,
        city,
        state,
        sex,
        type,
        date_of_birth,
        thumb: photo,
        mobileCode = '',
        country = '',
      } = this.props.patientProfile
      if (type === 2) {
        this.schema.date_of_birth = Joi.date()
          .required()
          .label('Date of birth')
          .error(errors => {
            errors.forEach(err => {
              switch (err.type) {
                case 'date.base':
                  err.message = dobRequired
                  break

                case 'date.max':
                  err.message = dobInvalid
                  break
              }
            })
            return errors
          })
      }
      const findCountry = Country.getAllCountries()
        .map(ele => ({
          ...ele,
          phonecode: ele.phonecode.startsWith('+')
            ? ele.phonecode
            : '+' + ele.phonecode,
        }))
        .find(
          ele =>
            ele.phonecode === (mobileCode ? mobileCode : '91') &&
            ele.name === (country ? country : 'India')
        )

      this.loadStateApi(findCountry ? findCountry.isoCode : 'IN')
      let stateValue = ''
      if (state) {
        const findState = State.getStatesOfCountry(
          findCountry ? findCountry.isoCode : 'IN'
        ).find(ele => ele.name === state)
        if (findState) {
          stateValue = findState.isoCode
          this.loadCityApi(
            findState.isoCode,
            findCountry ? findCountry.isoCode : 'IN'
          )
        }
      }

      const data = {
        photo,
        first_name,
        last_name,
        email,
        phone,
        sex,
        city: city ? { label: city, value: city } : null,
        state: state ? { label: state, value: stateValue } : null,
        date_of_birth: new Date(date_of_birth),
        mobileCode: mobileCode
          ? {
              label:
                (findCountry.phonecode.startsWith('+')
                  ? findCountry.phonecode
                  : '+' + findCountry.phonecode) +
                ' - ' +
                findCountry.name,
              value: findCountry.isoCode,
            }
          : {
              label: '+91 - India',
              value: 'IN',
              currLabel: '+91',
            },
        country: country ? country : 'India',
      }
      this.setState({
        data,
        disableMobile: phone ? true : false,
        disableEmail: email ? true : false,
        disableMobileCode: mobileCode ? true : false,
      })
      // this.props.loadState()
    }
    if (
      !this.props.getMisc.loadingState &&
      this.props.getMisc.loadingState !== prevProps.getMisc.loadingState
    ) {
      const states = this.props.getMisc.state.map(ele => ({
        label: ele,
        value: ele,
      }))
      //states.unshift({ _id: "", name: "eeeee" });
      this.setState({ states }, () => {
        window.$('#state').niceSelect('update')
        window.$('.state').on('change', () => {
          const data = this.state.data
          if (data.state !== window.$('#state').val()) {
            data.state = window.$('#state').val()
            this.setState({ data })
            //this.props.loadCity({ state: data.state })
            //this.loadCityApi(data.state)
          }
        })
      })
    }

    if (
      !this.props.getMisc.loadingCity &&
      this.props.getMisc.loadingCity !== prevProps.getMisc.loadingCity
    ) {
      const states = this.props.getMisc.state.map(ele => ({
        label: ele,
        value: ele,
      }))
      this.setState({ states }, () => {
        window.$('#city').niceSelect('update')
        window.$('.city').on('change', () => {
          const data = this.state.data
          if (data.city !== window.$('#city').val()) {
            data.city = window.$('#city').val()
            this.setState({ data })
          }
        })
      })
    }
    window.$('#state').niceSelect()
    window.$('.state').on('change', () => {
      const data = this.state.data
      if (data.state !== window.$('#state').val()) {
        data.state = window.$('#state').val()
        this.setState({ data })
        this.props.loadCity({ state: data.state })
      }
    })

    window.$('#city').niceSelect()
    window.$('.city').on('change', () => {
      const data = this.state.data
      if (data.city !== window.$('#city').val()) {
        data.city = window.$('#city').val()
        this.setState({ data })
      }
    })
  }
  fileUpload = file => {
    this.setState({ photoStatus: 'Loading' })
    const fileName = 'patient/photo/' + Date.now()
    const res = seUploadFun(file, fileName)
    res.then(result => {
      const { data, errors } = this.state
      data.photo = result.key
      errors.photo = false
      this.setState(
        {
          loading: false,
          photoStatus: 'Uploaded successfully. Click here to change',
          data,
          displayImage: process.env.REACT_APP_S3URL + result.key,
        },
        () => {
          this.toggle()
          this.handleSubmit()
        }
      )
    })
  }

  doSubmit = () => {
    if (localStorage.getItem('patient')) {
      const {
        email,
        first_name,
        last_name,
        date_of_birth,
        photo: thumb,
        state,
        city,
        sex,
        phone,
        mobileCode,
        country = '',
      } = this.state.data
      const data = {
        email,
        first_name,
        last_name,
        date_of_birth,
        thumb,
        state: state.label ? state.label : state,
        city: city.value ? city.value : city,
        sex,
        mobile: phone,
        mobileCode: mobileCode.currLabel,
        country,
      }

      this.props.updatePatientProfile(data, this.callBackUpdate)
    } else if (this.props.isDoctor) {
      const {
        email,
        first_name,
        last_name,
        date_of_birth,
        photo: thumb,
        state,
        city,
        sex,
        mobileCode,
        country = '',
      } = this.state.data
      const data = {
        email,
        first_name,
        last_name,
        date_of_birth,
        thumb,
        state: state.label ? state.label : state,
        city: city.value ? city.value : city,
        sex,
        mobileCode: mobileCode.currLabel,
        country,
      }
      this.props.updatePatientProfileByDoctor(
        data,
        this.props.patientId,
        this.callBackUpdate
      )
    } else {
      if (!this.state.loading) {
        this.setState({
          btnClass: 'btn btn-primary btn-lg disabled',
          loading: true,
        })
      }
    }
  }
  callBackUpdate = res => {
    this.setState({
      btnClass: 'btn btn-primary btn-lg',
      loading: false,
    })
    if (res && res.status === 200) {
      if (this.props.isDoctor) {
      } else {
        localStorage.setItem('patient', JSON.stringify(res.data))
        this.props.loadPatientProfile()
        //this.props.loadState()
      }
      this.props.updateStep(null, 2)
    } else {
      toast(<AlertError message={res.data.error} />)
    }
  }
  toggle = e => {
    if (e) e.preventDefault()
    this.setState({ model: !this.state.model })
  }
  readFile(file) {
    if (!file) return
    return new Promise(resolve => {
      const reader = new FileReader()
      reader.addEventListener('load', () => resolve(reader.result), false)
      reader.readAsDataURL(file)
    })
  }

  uploadFile = async e => {
    const file = e.target.files[0]
    let image = await this.readFile(file)
    this.setState({ image })
    this.toggle()
  }

  dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
      byteString = atob(dataURI.split(',')[1])
    else byteString = unescape(dataURI.split(',')[1])

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length)
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i)
    }

    return new Blob([ia], { type: mimeString })
  }
  onCropComplete = (croppedArea, croppedAreaPixels) => {
    this.setState({ croppedAreaPixels })
  }
  showCroppedImage = async e => {
    this.setState({ loading: true })
    //ref.current.continuousStart();
    e.preventDefault()
    try {
      const croppedImage = await getCroppedImg(
        this.state.image,
        this.state.croppedAreaPixels
      )

      return this.fileUpload(this.dataURItoBlob(croppedImage))
      const data = croppedImage
      let formData = new FormData()

      formData.append('image', this.dataURItoBlob(data))
      axios
        .post(
          process.env.REACT_APP_APIBASE + 'users/uploadProfilePicture',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              'jwt': localStorage.getItem('jwt'),
              'Authorization': process.env.REACT_APP_TOKEN,
              'Language': localStorage.getItem('Lang')
                ? localStorage.getItem('Lang')
                : 'gr',
            },
          }
        )
        .then(() => {
          //ref.current.complete();
          this.props.profileLoad()
          this.setState({ loading: false, model: false })
        })
        .catch(() => {
          //ref.current.complete();
          console.log('FAILURE!!')
          this.setState({ loading: false, model: false })
        })
    } catch (e) {
      console.error(e)
    }
  }
  render() {
    const cities = this.props.getMisc.city.map(e => ({
      label: e.city,
      value: e._id,
    }))
    const { isDoctor = false } = this.props
    return (
      <>
        <div className='dashboard-content-box'>
          {window.location.pathname ===
          '/patient/dashboard/profile-information' ? (
            ''
          ) : (
            <DashboardTopBar
              step={this.props.step}
              updateStep={this.props.updateStep}
              isDoctor={isDoctor}
            />
          )}
          <form onSubmit={this.handleSubmit}>
            <div
              className={
                window.location.pathname ===
                '/patient/dashboard/profile-information'
                  ? ''
                  : 'dc-body d-flex flex-wrap align-items-lg-center'
              }>
              <div className='dc-left'>
                {/* <h4 className="d-block d-xl-none">Personal Details</h4> */}
                <div className='dc-profile-box d-flex align-items-center'>
                  <div className='d-flex align-items-center'>
                    <div className='dc-profile-img'>
                      <img
                        src={
                          this.state.data.photo
                            ? process.env.REACT_APP_S3URL +
                              this.state.data.photo
                            : avatar
                        }
                        alt=''
                      />
                    </div>
                    <div className='dc-profile-intro'>
                      <span>Profile Picture</span>
                      <label
                        htmlFor='photograph2'
                        className='btn btn-outline1 btn-xs mt-2'>
                        Upload
                      </label>
                      <input
                        accept='image/*'
                        type='file'
                        id='photograph2'
                        className='d-none errorColor'
                        onChange={this.uploadFile}
                        disabled={this.state.photoStatus === 'Loading'}
                      />

                      {this.state.errors.photo && (
                        <label className='errorLabel'>
                          {this.state.errors.photo}
                        </label>
                      )}
                    </div>
                  </div>
                  {window.location.pathname ===
                    '/patient/dashboard/profile-information' &&
                    this.props.caseRecord &&
                    this.props.caseRecord.finish && (
                      <div className='dc-check-icon'>
                        <img src={verify} alt='' />
                        <span>Verified Patient</span>
                      </div>
                    )}
                </div>
                <div className='dc-form-box'>
                  <div className='row'>
                    <div className='col-md-6'>
                      {this.renderInput(
                        'first_name',
                        this.props.patientProfile &&
                          this.props.patientProfile.type &&
                          this.props.patientProfile.type == 2
                          ? 'Patient First Name'
                          : 'First Name',
                        'text',
                        this.props.patientProfile &&
                          this.props.patientProfile.first_name
                          ? true
                          : false
                      )}
                    </div>
                    <div className='col-md-6'>
                      {this.renderInput(
                        'last_name',
                        this.props.patientProfile &&
                          this.props.patientProfile.type &&
                          this.props.patientProfile.type == 2
                          ? 'Patient Last Name'
                          : 'Last Name',
                        'text',
                        this.props.patientProfile &&
                          this.props.patientProfile.last_name
                          ? true
                          : false
                      )}
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-6'>
                      {this.props.patientProfile &&
                          this.props.patientProfile.date_of_birth && this.renderDateInput(
                        'date_of_birth',
                        'Date of birth',
                        '',
                        '',
                          this.props.patientProfile.date_of_birth
                          ? true
                          : false
                      )}
                    </div>
                    {this.state.data.date_of_birth && (
                      <div className='col-md-6'>
                        <div className='form-group ml-2'>
                          <label htmlFor='age'>
                            Age :{' '}
                            {moment().diff(
                              this.state.data.date_of_birth,
                              'years'
                            )}
                            {' Y'}
                          </label>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='row'>
                    <div className='col-md-6'>
                      {this.renderSelect('sex', 'Sex', [
                        { id: 'M', name: 'Male' },
                        { id: 'F', name: 'Female' },
                      ])}
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-6'>
                      <Select
                        styles={this.customStyles}
                        isDisabled={this.state.disableMobileCode}
                        onChange={e => {
                          const data = { ...this.state.data }
                          data.mobileCode = {
                            label: e.label,
                            value: e.value,
                            currLabel: e.currLabel,
                          }
                          data.country = e.name
                          this.loadStateApi(e.value)
                          this.setState({ data })
                        }}
                        placeholder='Select Country Code'
                        value={this.state.data.mobileCode}
                        defaultValue={this.state.data.mobileCode}
                        getOptionLabel={x => x.label}
                        getOptionValue={x => x.value}
                        options={Country.getAllCountries().map(ele => ({
                          label:
                            (ele.phonecode.startsWith('+')
                              ? ele.phonecode
                              : '+' + ele.phonecode) +
                            ' - ' +
                            ele.name,
                          value: ele.isoCode,
                          name: ele.name,
                          currLabel: ele.phonecode.startsWith('+')
                            ? ele.phonecode
                            : '+' + ele.phonecode,
                        }))}
                      />
                    </div>
                    <div className='col-md-6'>
                      {this.renderInput(
                        'phone',
                        'Mobile Number',
                        'text',
                        this.state.disableMobile
                      )}
                    </div>
                    <div className='col-md-6'>
                      {this.renderInput(
                        'email',
                        'Email',
                        'text',
                        this.state.disableEmail
                      )}
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-6'>
                      <Select
                        styles={this.customStyles}
                        onChange={e => {
                          const data = { ...this.state.data }
                          data.state = e
                          data.city = ''
                          this.setState({ data })
                          //this.props.loadCity({ state: e.value })
                          this.loadCityApi(
                            e.value,
                            this.state.data.mobileCode.value
                          )
                        }}
                        placeholder='Select State'
                        value={this.state.data.state}
                        defaultValue={this.state.data.state}
                        getOptionLabel={x => x.label}
                        getOptionValue={x => x.value}
                        //options={this.state.states}
                        options={this.state.stateData}
                      />
                    </div>
                    <div className='col-md-6'>
                      {cities && (
                        <Select
                          styles={this.customStyles}
                          onChange={e => {
                            const data = { ...this.state.data }
                            data.city = e
                            this.setState({ data })
                          }}
                          placeholder='Select City'
                          value={this.state.data.city}
                          defaultValue={this.state.data.city}
                          getOptionLabel={x => x.label}
                          getOptionValue={x => x.value}
                          options={this.state.cityData}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {window.location.pathname ===
              '/patient/dashboard/profile-information' ? (
                ''
              ) : (
                <div className='dc-right'>
                  <div className='dc-right-content'>
                    <div className='dc-img-box'>
                      <img src={rocket} alt='' />
                    </div>
                    <h1>
                      Welcome to <span>Yes</span>Mindy
                    </h1>
                    <p>Let's complete your profile</p>
                  </div>
                </div>
              )}
            </div>
            {window.location.pathname ===
            '/patient/dashboard/profile-information' ? (
              <div className='dc-bottom-box'>
                <SubmitBtn
                  label='SAVE and NEXT'
                  loading={this.state.loading}
                  btnClass='btn btn-default no-shadow'
                />
              </div>
            ) : (
              <div className='dc-footer'>
                <SubmitBtn
                  label={'SAVE and NEXT'}
                  loading={this.state.loading}
                  btnClass={this.state.btnClass}
                />
              </div>
            )}
          </form>
        </div>
        <Modal
          className='modal fade process-popup crop-popup verification-popup'
          dialogClassName='modal-dialog modal-dialog-centered'
          show={this.state.model}
          onHide={this.toggle}
          size='lg'
          aria-labelledby='contained-modal-title-vcenter'
          centered>
          <Modal.Header closeButton>
            <Modal.Title id='contained-modal-title-vcenter'>Crop</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ minHeight: '400px' }}>
            <p>
              <Cropper
                image={this.state.image}
                crop={this.state.crop}
                zoom={this.state.zoom}
                aspect={4 / 4}
                onCropChange={e => this.setState({ crop: e })}
                onCropComplete={this.onCropComplete}
              />
            </p>
          </Modal.Body>
          <div className='crop-zoom'>
            {/* <label for="customRange1" className="form-label">
              Zoom
            </label> */}
            <input
              style={{ width: '100%' }}
              max='100'
              min='1'
              type='range'
              className='form-range'
              id='customRange1'
              value={this.state.zoom}
              onChange={e => this.setState({ zoom: e.target.value })}
            />
          </div>
          <Modal.Footer>
            {!this.state.loading ? (
              <a
                href='javascript:void(0)'
                onClick={this.showCroppedImage}
                className='btn btn-default'>
                Crop
              </a>
            ) : (
              'Uploading...'
            )}
          </Modal.Footer>
        </Modal>
      </>
    )
  }
}
const mapStateToProps = state => ({
  getMisc: getMisc(state),
  caseRecord: getPatient(state).caseRecord,
  patientProfile: getPatient(state).profile,
  loading: getPatient(state).loading,
})
const mapDispatchToProps = dispatch => ({
  updatePatientProfile: (param, callback) =>
    dispatch(updatePatientProfile(param, callback)),
  updatePatientProfileByDoctor: (param, id, callback) =>
    dispatch(updatePatientProfileByDoctor(param, id, callback)),
  loadState: () => dispatch(loadState()),
  loadCity: data => dispatch(loadCity(data)),
  loadPatientProfile: () => dispatch(loadPatientProfile()),
  loadPatientProfileDoctor: id => dispatch(patientDetail(id, true)),
})
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PatientDetails)
)
